
<header id="main-site-header" [ngClass]="{'custom-bg-gray': !isItemPage, 'custom-bg-gradient': isItemPage}">
  <!--Redes sociales-->
  <div class="container social-icons" style="float:right; margin-right:12rem; text-align:right">
    <div style="display: flex; gap: 10px; justify-content: right; align-items: right;">
      <!-- Ícono de Twitter -->
      <div style="background-color: #acb8d1; border-radius: 50%; display: flex; justify-content: center; align-items: center; width: 40px; height: 40px;">
        <i class="fab fa-twitter" style="color: black; font-size: 20px;"></i>
      </div>
      <!-- Ícono de Facebook -->
      <div style="background-color: #acb8d1; border-radius: 50%; display: flex; justify-content: center; align-items: center; width: 40px; height: 40px;">
        <i class="fab fa-facebook-f" style="color: black; font-size: 20px;"></i>
      </div>
      <!-- Ícono de LinkedIn -->
      <div style="background-color: #acb8d1; border-radius: 50%; display: flex; justify-content: center; align-items: center; width: 40px; height: 40px;">
        <i class="fab fa-linkedin-in" style="color: black; font-size: 20px;"></i>
      </div>
    </div>
  </div>

  <div class="container h-100 d-flex flex-row flex-wrap align-items-center justify-content-between gapx-3 gapy-2" id="main-site-header-container">
    <div id="header-left"
         [attr.role]="(isMobile$ | async) ? 'navigation' : 'presentation'"
         [attr.aria-label]="(isMobile$ | async) ? ('nav.main.description' | translate) : null"
         class="h-100 flex-fill d-flex flex-row flex-nowrap justify-content-between align-items-center gapx-3">
      
      <a class="d-block my-2 my-md-0" routerLink="/home" [attr.aria-label]="'home.title' | translate">
        <img id="header-logo" src="assets/images/UNE-logov3.png" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>
      <nav *ngIf="(isMobile$ | async) !== true" class="navbar navbar-expand p-0 align-items-stretch align-self-stretch" id="desktop-navbar" [attr.aria-label]="'nav.main.description' | translate">
        <ds-navbar></ds-navbar>
      </nav>
    </div>

    <!-- Search bar and other menus -->
    <div id="header-right" class="h-100 d-flex flex-row flex-nowrap justify-content-end align-items-center gapx-1 ml-auto">
      <!--<ds-search-navbar></ds-search-navbar>-->
      <div role="menubar" class="h-100 d-flex flex-row flex-nowrap align-items-center gapx-1">
        <ds-lang-switch></ds-lang-switch>
        <ds-context-help-toggle></ds-context-help-toggle>
        <ds-impersonate-navbar></ds-impersonate-navbar>
        <!--<ds-auth-nav-menu></ds-auth-nav-menu>-->
      </div>

      <div id="mobile-navbar-toggler" class="d-block d-lg-none ml-3" *ngIf="(isMobile$ | async)">
        <button id="navbar-toggler" class="btn" type="button" (click)="toggleNavbar()"
                [attr.aria-label]="'nav.toggle' | translate" aria-controls="collapsible-mobile-navbar" [attr.aria-expanded]="(isNavBarCollapsed$ | async) !== true">
          <span class="fas fa-bars fa-fw fa-xl toggler-icon" aria-hidden="true"></span>
        </button>
      </div>

    </div>
  </div>
</header>
